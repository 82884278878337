import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-up-button"
export default class extends Controller {
  static targets = [ "hide" ];

  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    document.addEventListener("scroll", this.scroll);
  }

  scroll() {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      this.hideTarget.style.display = "block";
    } else {
      this.hideTarget.style.display = "none";
    }
  }
}
