import Sortable from 'stimulus-sortable'
import Rails from "@rails/ujs"

// Connects to data-controller="sort"

export default class extends Sortable {
  connect() {
    super.connect()

    // The sortable.js instance.
    this.sortable

    // Your options
    this.options

    // Your default options
    this.defaultOptions
  }

  // You can override the `onUpdate` method here.
  onUpdate(event) {
    super.onUpdate(event)
    var allItems = event.target;
    const upForms = allItems.getElementsByClassName("move-up");
    const downForms = allItems.getElementsByClassName("move-up");

    var upIdx = parseInt(this.getStartPosition()) + 1
    Array.from(upForms).forEach((form) => {
      var action = form.getAttribute("action")
      var oldValue = action.split("/")
      oldValue = oldValue[oldValue.length - 1]
      form.setAttribute("action", action.replaceAll(oldValue, upIdx))
      upIdx = upIdx + 1
    });

    upIdx = parseInt(this.getStartPosition()) - 1
    Array.from(downForms).forEach((form) => {
      var action = form.getAttribute("action")
      var oldValue = action.split("/")
      oldValue = oldValue[oldValue.length - 1]
      form.setAttribute("action", action.replaceAll(oldValue, upIdx))
      upIdx = upIdx + 1
    });

    var movedItem = allItems.querySelector("div[draggable='false']");
    var itemId = movedItem.id.replaceAll(`${this.getRecordType().toLowerCase()}_`, '')
    var newPosition = movedItem.getElementsByClassName("move-up")[0].getAttribute("action").split("/")
    newPosition = parseInt(newPosition[newPosition.length - 1]) + 1
    movedItem.removeAttribute("draggable")

    if (this.getRecordType() == "Card") {
      Rails.ajax({
        url: `/decks/${this.getParent()}/cards/${itemId}/update_position/${newPosition}`,
        type: 'patch'
      });
    }
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 250,
    }
  }

  getStartPosition() {
		return this.data.get('start-position');
	}

  getRecordType() {
    return this.data.get('record-type');
  }

  getParent() {
    return this.data.get('parent');
  }
}
