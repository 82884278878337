import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-link-data-params-with-local-time"
export default class extends Controller {
  static targets = [ "link" ]

  connect() {
    this.updateEvents();
  }

  updateEvents() {
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(tz)
    console.log(this.linkTarget.href)
    //http://localhost:3000/calendars/109/by_day?day=12&month=7&year=2024#Event_1118

    var new_date = new Date(this.getDateTime())

    console.log(this.getDateTime())
    console.log(new_date)
    console.log(new_date.getDate())
    console.log(new_date.getFullYear())
    console.log(new_date.getMonth()+1)

    var link_pre = this.linkTarget.href.split('?')[0] + "?"
    var link_post = "#" + this.linkTarget.href.split('#')[1]
    var link_params = `day=${new_date.getDate()}&month=${new_date.getMonth()+1}&year=${new_date.getFullYear()}`


    this.linkTarget.href = link_pre + link_params+ link_post
  }

  getDateTime() {
		return this.data.get('datetime');
	}
}
