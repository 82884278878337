document.addEventListener("turbo:load", () => {
  // init Masonry
  var $grid = $('.grid').masonry({
    percentPosition: true
  });

  // layout Masonry after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.masonry('layout');
  });
})
