import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="get-local-time-events"
export default class extends Controller {
  initialize() {
    this.updateEvents = this.updateEvents.bind(this);
  }

  connect() {
    this.updateEvents();
  }

  updateEvents() {
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var form = "";
    if (this.getView() == 'monthly') {
      form = `<form class="button_to" method="post" action="/calendars/${this.getCalendarID()}/update_events_for_tz?year=${this.getYear()}&month=${this.getMonth()}&day=${this.getDay()}&view=monthly&tz=${tz}"><button class="btn btn-danger btn-sm" id="click_me" type="submit" style="display:none;">Update Calendar</button><input type="hidden"></form>`
    } else if (this.getView() == 'daily') {
      form = `<form class="button_to" method="post" action="/calendars/${this.getCalendarID()}/update_events_for_tz?year=${this.getYear()}&month=${this.getMonth()}&day=${this.getDay()}&page=${this.getPage()}&view=daily&tz=${tz}"><button class="btn btn-danger btn-sm" id="click_me" type="submit" style="display:none;">Update Calendar</button><input type="hidden"></form>`
    }
    var eventReload = document.querySelector("#event_reload");
    eventReload.innerHTML = form;
    document.querySelector("#click_me").click();
    eventReload.innerHTML = "";
  }

  getCalendarID() {
		return this.data.get('calendar-id');
	}

  getYear() {
		return this.data.get('year');
	}

  getMonth() {
		return this.data.get('month');
	}

  getDay() {
		return this.data.get('day');
	}

  getView() {
		return this.data.get('view');
	}

  getPage() {
		return this.data.get('page');
	}
}
