import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="real-time-chat-message"
export default class extends Controller {

  connect() {
    this.scrollDown();
    this.markRead();
  }

  scrollDown() {
    var windowSize = $('#messages').height();
    var scrollSize = $('#messages')[0].scrollHeight;
    var scrollPosition = $('#messages').scrollTop();
    var trackLength = scrollSize - windowSize;
    if ((scrollPosition + 400) > trackLength) {
      return $('#messages').scrollTop($('#messages')[0].scrollHeight);
    }
  }

  markRead() {
    //var currentUsername = document.getElementById("current-username").innerHTML;
    Rails.ajax({
			url: `/chats/${this.getID()}/clear_unread_messages`,
			type: 'post'
		});
  }

  getID() {
		return this.data.get('id');
	}
}
