import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="toggle-owned"
export default class extends Controller {
  static targets = [ "toggle", "submit" ];

  connect() {
    var query = window.location.search;
    if (query.includes("view_owned=1")) {
      this.toggleTarget.checked = true;
    }
  }

  toggle() {
    Rails.fire(this.submitTarget, 'submit');
  }
}
