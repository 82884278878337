import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';

// Connects to "calendar-date-form"
export default class extends Controller {
  static targets = ["date"]

  connect() {
    this.picker = new TempusDominus(this.dateTarget, {
      localization: {
        locale: 'default',
        format: 'MMM dd, yyyy'
      },
      display: {
        theme: 'dark',
        icons: {
          time: 'bi bi-clock',
          date: 'bi bi-calendar',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        },
        components: {
          clock: false,
          hours: false,
          minutes: false,
          seconds: false,
          useTwentyfourHour: undefined
        }
      }
    })
  }

  submit(event) {
    event.preventDefault()
    const date = this.picker.dates.picked[0]
    if (date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const path = this.data.get('path')
      const url = `${path}?year=${year}&month=${month}&day=${day}`
      window.location.href = url
    }
  }
}
