import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "card", "front", "back" ];

  initialize() {
    this.control = this.control.bind(this);
  }

  connect() {
    document.addEventListener("keydown", this.control);
    this.touchStartX = 0;
    this.touchEndX = 0;
    this.touchStartY = 0;
    this.touchEndY = 0;
    this.touchStartTime = 0;
    this.timeThreshold = 300;
    this.swipeThreshold = 100;
  }

  disconnect() {
    document.removeEventListener("keydown", this.control);
  }

  startTouch(event) {
    this.touchStartX = event.changedTouches[0].screenX;
    this.touchStartY = event.changedTouches[0].screenY;
    this.touchStartTime = new Date().getTime();
  }

  moveTouch(event) {
    this.touchEndX = event.changedTouches[0].screenX;
    this.touchEndY = event.changedTouches[0].screenY;
  }

  endTouch(Event) {
    const touchEndTime = new Date().getTime();
    const timeElapsed = touchEndTime - this.touchStartTime;
    const horizontalDistance = this.touchEndX - this.touchStartX;
    const verticalDistance = this.touchEndY - this.touchStartY;

    if (Math.abs(horizontalDistance) >  Math.abs(verticalDistance) && Math.abs(horizontalDistance) >  this.swipeThreshold && timeElapsed < this.timeThreshold) {
      return;
    }

    if (this.touchEndX < this.touchStartX) {
      this.swipeLeft();
    } else if (this.touchEndX > this.touchStartX) {
      this.swipeRight();
    }
  }

  swipeRight() {
    var prevBtn = document.getElementsByClassName('btn-prev')[0]
    if (prevBtn) {
      prevBtn.click()
    }
  }

  swipeLeft() {
    var nextBtn = document.getElementsByClassName('btn-next')[0]
    if (nextBtn) {
      nextBtn.click()
    }
  }

  control(event) {
    if (event.keyCode === 32 || event.keyCode === 49) {
      this.flip();
    } else if (event.keyCode === 37) {
      document.getElementById('prev-card').click();
    } else if (event.keyCode === 39) {
      document.getElementById('next-card').click();
    }
  }

  flip() {
    if (this.frontTarget.style.display === "none") {
      this.frontTarget.style.display = "block";
      this.backTarget.style.display = "none";
    } else {
      this.frontTarget.style.display = "none";
      this.backTarget.style.display = "block";
    }
  }
}
