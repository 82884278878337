// Prevent uploading of big images.
document.addEventListener("turbo:load", function() { document.addEventListener("change", function(event) {
  let image_uploads = document.querySelectorAll("#user_avatar,#site_setting_logo,#site_setting_apple_touch_icon,#site_setting_default_avatar,#site_setting_favicon");
  for (var i = 0; i < image_uploads.length; i++){
    if (image_uploads[i].files[0]) {
      const size_in_megabytes = image_uploads[i].files[0].size/1024/1024;
      if (size_in_megabytes > 5) {
        alert("Maximum file size is 5MB. Please choose a smaller file.");
        image_uploads[i].value = "";
      }
    }
  }
}); });
