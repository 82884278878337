import { Controller } from "@hotwired/stimulus"
import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';

// Connects to data-controller="rrule-form"
export default class extends Controller {
  static targets = [ "su", "mo", "tu", "we", "th", "fr", "sa", "startDateTime", "rruleHidden", "rrule", "frequency", "yearly", "yearlyType", "yearlyOn", "yearlyOnMonth", "yearlyOnMonthDay", "yearlyOnThe", "yearlyOnTheNth", "yearlyOnTheDay", "yearlyOnTheMonth", "monthly", "monthlyInterval", "monthlyType", "monthlyOn", "monthlyOnDay", "monthlyOnTheNth", "monthlyOnTheDay", "weekly", "weeklyInterval", "weeklyDays", "daily", "dailyInterval", "endType", "endInterval", "endIntervalValue", "endIntervalDate" ];

  initialize() {
    this.setRrule = this.setRrule.bind(this);
    this.updateRrule = this.updateRrule.bind(this);
  }

  connect() {
    this.set_date_picker();
    this.setRrule();
    document.addEventListener("change", this.updateRrule);
    document.getElementById('linkedPickers1').addEventListener("change.td", this.updateRrule);
    document.getElementById('rruleEndDate').addEventListener("change.td", this.updateRrule);
  }

  getDateFromDateString(dateString){
    const [month, day, year, time] = dateString.split(/[\s,]+/);
    const date = new Date(year, new Date(`${year} ${month} 01`).getMonth(), day);
    return date;
  }

  setRrule(){
    var rules = this.rruleTarget.value.split(";");
    if (this.rruleTarget.value.includes("FREQ=YEARLY;")) {
      this.frequencyTarget.value = "YEARLY";
      this.yearlyTarget.hidden = false;
      this.monthlyTarget.hidden = true;
      this.weeklyTarget.hidden = true;
      this.dailyTarget.hidden = true;
      if (this.rruleTarget.value.includes("BYMONTH=") && this.rruleTarget.value.includes("BYMONTHDAY=")) {
        this.yearlyTypeTarget.value = "on"
        rules.forEach((rule) => {
          if (rule.includes("BYMONTH=")) {
            this.yearlyOnMonthTarget.value = rule.split("=")[1];
          }
          if (rule.includes("BYMONTHDAY=")) {
            this.yearlyOnMonthDayTarget.value = rule.split("=")[1];
          }
        });
        this.yearlyOnTarget.hidden = false;
        this.yearlyOnTheTarget.hidden = true;
      } else if (this.rruleTarget.value.includes("BYMONTH=") && this.rruleTarget.value.includes("BYDAY=")) {
        this.yearlyTypeTarget.value = "onthe"
        rules.forEach((rule) => {
          if (rule.includes("BYSETPOS=")) {
            this.yearlyOnTheNthTarget.value = rule.split("=")[1];
          }
          if (rule.includes("BYDAY=")) {
            this.yearlyOnTheDayTarget.value = rule.split("=")[1];
          }
          if (rule.includes("BYMONTH=")) {
            this.yearlyOnTheMonthTarget.value = rule.split("=")[1];
          }
        });
        this.yearlyOnTarget.hidden = true;
        this.yearlyOnTheTarget.hidden = false;
      }
    } else if (this.rruleTarget.value.includes("FREQ=MONTHLY;")) {
      this.frequencyTarget.value = "MONTHLY";
      this.yearlyTarget.hidden = true;
      this.monthlyTarget.hidden = false;
      this.weeklyTarget.hidden = true;
      this.dailyTarget.hidden = true;
      if (this.rruleTarget.value.includes("BYMONTHDAY=")) {
        this.monthlyTypeTarget.value = "on"
        rules.forEach((rule) => {
          if (rule.includes("INTERVAL=")) {
            this.monthlyIntervalTarget.value = rule.split("=")[1]
          }
          if (rule.includes("BYMONTHDAY=")) {
            this.monthlyOnDayTarget.value = rule.split("=")[1]
          }
        });
        this.monthlyOnTarget.hidden = false;
        this.monthlyOnTheNthTarget.hidden = true;
        this.monthlyOnTheDayTarget.hidden = true;
      } else if (this.rruleTarget.value.includes("BYDAY=")) {
        this.monthlyTypeTarget.value = "onthe";
        rules.forEach((rule) => {
          if (rule.includes("INTERVAL=")) {
            this.monthlyIntervalTarget.value = rule.split("=")[1];
          }
          if (rule.includes("BYSETPOS=")) {
            this.monthlyOnTheNthTarget.value = rule.split("=")[1];
          }
          if (rule.includes("BYDAY=")) {
            this.monthlyOnTheDayTarget.value = rule.split("=")[1];
          }
        });
        this.monthlyOnTarget.hidden = true;
        this.monthlyOnTheNthTarget.hidden = false;
        this.monthlyOnTheDayTarget.hidden = false;
      }
    } else if (this.rruleTarget.value.includes("FREQ=WEEKLY;")) {
      this.frequencyTarget.value = "WEEKLY";
      rules.forEach((rule) => {
        if (rule.includes("INTERVAL=")) {
          this.weeklyIntervalTarget.value = rule.split("=")[1];
        }
        if (rule.includes("BYDAY=")) {
          rule.split("=")[1].split(",").forEach((day) => {
            if (day == "SU") {
              this.suTarget.checked = true;
            } else if (day == "MO") {
              this.moTarget.checked = true;
            } else if (day == "TU") {
              this.tuTarget.checked = true;
            } else if (day == "WE") {
              this.weTarget.checked = true;
            } else if (day == "TH") {
              this.thTarget.checked = true;
            } else if (day == "FR") {
              this.frTarget.checked = true;
            } else if (day == "SA") {
              this.saTarget.checked = true;
            }
          });
        }
      });
      this.yearlyTarget.hidden = true;
      this.monthlyTarget.hidden = true;
      this.weeklyTarget.hidden = false;
      this.dailyTarget.hidden = true;
    } else if (this.rruleTarget.value.includes("FREQ=DAILY;")) {
      rules.forEach((rule) => {
        if (rule.includes("INTERVAL=")) {
          this.dailyIntervalTarget.value = rule.split("=")[1]
        }
      });
      this.frequencyTarget.value = "DAILY";
      this.yearlyTarget.hidden = true;
      this.monthlyTarget.hidden = true;
      this.weeklyTarget.hidden = true;
      this.dailyTarget.hidden = false;
    }

    if (this.rruleTarget.value.includes("COUNT=")){
      rules.forEach((rule) => {
        if (rule.includes("COUNT=")) {
          this.endIntervalValueTarget.value = rule.split("=")[1];
        }
      });
      this.endTypeTarget.value = "count";
      this.endIntervalTarget.hidden = false;
      this.endIntervalDateTarget.hidden = true;
    } else if (this.rruleTarget.value.includes("UNTIL=")) {
      rules.forEach((rule) => {
        if (rule.includes("UNTIL=")) {
          this.endIntervalDateTarget.value = rule.split("=")[1];
        }
      });
      this.endTypeTarget.value = "until";
      this.endIntervalTarget.hidden = true
      this.endIntervalDateTarget.hidden = false
    }
  }

  updateRrule(){
    var rrule = "";
    var nth = ['1', '2', '3', '4', '5', '6'];
    var date;
    date = this.getDateFromDateString(this.startDateTimeTarget.value);
    var dayOfWeek = date.getDay();
    var monthday = date.getDate();
    var month = date.getMonth() + 1;
    var monthStartDayOffset = new Date(date.getFullYear(), new Date(`${month} 01`).getMonth(), 1).getDay();
    var nthDay;
    if (((monthday+monthStartDayOffset) % 7) == 0) {
      nthDay = nth[((monthday+monthStartDayOffset)/7)-1];
    } else if (dayOfWeek >= monthStartDayOffset) {
      nthDay = nth[Math.floor(((monthday+monthStartDayOffset)/7))];
    } else {
      nthDay = nth[Math.floor(((monthday-monthStartDayOffset)/7))];
    }
    var daysInMonth = new Date(date.getFullYear(), new Date(`${month} 01`).getMonth() + 1, 0).getDate();
    var weeksInMonth;
    if (((daysInMonth+monthStartDayOffset) % 7) == 0) {
      weeksInMonth = nth[((daysInMonth+monthStartDayOffset)/7)-1];
    } else {
      weeksInMonth = nth[Math.floor(((daysInMonth+monthStartDayOffset)/7))];
    }
    var lastDayOfMonth = new Date(date.getFullYear(), month, 0);
    var lastWeekdayOfMonth = lastDayOfMonth.getDate() - (lastDayOfMonth.getDay() - dayOfWeek);
    if (lastWeekdayOfMonth > lastDayOfMonth.getDate()) {
      lastWeekdayOfMonth = lastWeekdayOfMonth - 7;
    }
    var lastWeekOfMonth = false;
    if (monthday === lastWeekdayOfMonth) {
      lastWeekOfMonth = true;
    }

    //var monthdays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var weekDays = ["SU","MO","TU","WE","TH","FR","SA"];

    if (this.frequencyTarget.value == "YEARLY") {
      this.yearlyTarget.hidden = false;
      this.monthlyTarget.hidden = true;
      this.weeklyTarget.hidden = true;
      this.dailyTarget.hidden = true;
      rrule = rrule + "FREQ=YEARLY;";
      if (this.yearlyTypeTarget.value == "on") {
        this.yearlyOnTarget.hidden = false;
        this.yearlyOnTheTarget.hidden = true;
        this.yearlyOnMonthTarget.value = month;
        this.yearlyOnMonthDayTarget.value = monthday;
        rrule = rrule + `BYMONTH=${month};BYMONTHDAY=${monthday};`;
      } else if (this.yearlyTypeTarget.value == "onthe") {
        this.yearlyOnTarget.hidden = true;
        this.yearlyOnTheTarget.hidden = false;
        this.yearlyOnTheDayTarget.value = weekDays[dayOfWeek];
        this.yearlyOnTheMonthTarget.value = month;
        rrule = rrule + `BYMONTH=${month};BYDAY=${weekDays[dayOfWeek]};`;
        if (lastWeekOfMonth == true) {
          this.yearlyOnTheNthTarget.disabled = false;
          this.yearlyOnTheNthTarget.childNodes.forEach((option) => {
            if (option.value && !option.value.includes("-1") && !option.value.includes(nthDay)) {
              option.disabled = true;
            } else {
              option.disabled = false;
            }
          });
          if (this.yearlyOnTheNthTarget.value != "-1") {
            this.yearlyOnTheNthTarget.value = nthDay;
            rrule = rrule + `BYSETPOS=${nthDay};`;
          } else {
            rrule = rrule + `BYSETPOS=-1;`;
          }
        } else {
          this.yearlyOnTheNthTarget.disabled = true;
          this.yearlyOnTheNthTarget.value = nthDay;
          rrule = rrule + `BYSETPOS=${nthDay};`;
        }
      }
    } else if (this.frequencyTarget.value == "MONTHLY") {
      this.yearlyTarget.hidden = true;
      this.monthlyTarget.hidden = false;
      this.weeklyTarget.hidden = true;
      this.dailyTarget.hidden = true;
      rrule = rrule + `FREQ=MONTHLY;INTERVAL=${this.monthlyIntervalTarget.value};`;
      if (this.monthlyTypeTarget.value == "on") {
        this.monthlyOnTarget.hidden = false;
        this.monthlyOnTheNthTarget.hidden = true;
        this.monthlyOnTheDayTarget.hidden = true;
        this.monthlyOnDayTarget.value = monthday;
        rrule = rrule + `BYMONTHDAY=${monthday};`;
      } else if (this.monthlyTypeTarget.value == "onthe") {
        this.monthlyOnTarget.hidden = true;
        this.monthlyOnTheNthTarget.hidden = false;
        this.monthlyOnTheDayTarget.hidden = false;
        this.monthlyOnTheDayTarget.value = weekDays[dayOfWeek];
        rrule = rrule + `BYDAY=${weekDays[dayOfWeek]};`;
        if (lastWeekOfMonth == true){
          this.monthlyOnTheNthTarget.disabled = false;
          this.monthlyOnTheNthTarget.childNodes.forEach((option) => {
            if (option.value && !option.value.includes("-1") && !option.value.includes(nthDay)) {
              option.disabled = true;
            } else {
              option.disabled = false;
            }
          });
          if (this.monthlyOnTheNthTarget.value != "-1") {
            this.monthlyOnTheNthTarget.value = nthDay;
            rrule = rrule + `BYSETPOS=${nthDay};`;
          } else {
            rrule = rrule + `BYSETPOS=-1;`;
          }
        } else {
          this.yearlyOnTheNthTarget.disabled = true;
          this.monthlyOnTheNthTarget.value = nthDay;
          rrule = rrule + `BYSETPOS=${nthDay};`;
        }
      }
    } else if (this.frequencyTarget.value == "WEEKLY") {
      this.yearlyTarget.hidden = true;
      this.monthlyTarget.hidden = true;
      this.weeklyTarget.hidden = false;
      this.dailyTarget.hidden = true;
      rrule = rrule + `FREQ=WEEKLY;INTERVAL=${this.weeklyIntervalTarget.value};`;
      var daysStr = "";
      if (this.suTarget.checked == true || dayOfWeek == 0) {
        daysStr = daysStr + "SU,";
        this.suTarget.checked = true;
      }
      if (this.moTarget.checked == true || dayOfWeek == 1) {
        daysStr = daysStr + "MO,";
        this.moTarget.checked = true;
      }
      if (this.tuTarget.checked == true || dayOfWeek == 2) {
        daysStr = daysStr + "TU,";
        this.tuTarget.checked = true;
      }
      if (this.weTarget.checked == true || dayOfWeek == 3) {
        daysStr = daysStr + "WE,";
        this.weTarget.checked = true;
      }
      if (this.thTarget.checked == true || dayOfWeek == 4) {
        daysStr = daysStr + "TH,";
        this.thTarget.checked = true;
      }
      if (this.frTarget.checked == true || dayOfWeek == 5) {
        daysStr = daysStr + "FR,";
        this.frTarget.checked = true;
      }
      if (this.saTarget.checked == true || dayOfWeek == 6) {
        daysStr = daysStr + "SA,";
        this.saTarget.checked = true;
      }
      daysStr = "BYDAY=" + daysStr;
      daysStr = daysStr.substring(0,daysStr.length-1);
      rrule = rrule + daysStr + ";";
    } else if (this.frequencyTarget.value == "DAILY") {
      rrule = rrule + `FREQ=DAILY;INTERVAL=${this.dailyIntervalTarget.value};`;
      this.yearlyTarget.hidden = true;
      this.monthlyTarget.hidden = true;
      this.weeklyTarget.hidden = true;
      this.dailyTarget.hidden = false;
    }

    if (this.endTypeTarget.value == "count") {
      rrule = rrule + `COUNT=${this.endIntervalValueTarget.value};`;
      this.endIntervalTarget.hidden = false;
      this.endIntervalDateTarget.hidden = true;
    } else if (this.endTypeTarget.value == "until") {
      rrule = rrule + `UNTIL=${this.endIntervalDateTarget.value};`;
      this.endIntervalTarget.hidden = true
      this.endIntervalDateTarget.hidden = false
    }

    if (rrule.charAt(rrule.length - 1) == ";") {
      rrule = rrule.substring(0,rrule.length-1);
    }
    this.rruleTarget.value = rrule;
    this.rruleHiddenTarget.value = rrule;
  }

  set_date_picker() {
    const rruleEndDate = new TempusDominus(document.getElementById('rruleEndDate'), {
      useCurrent: false,
      localization: {
        locale: 'default',
        format: 'yyyymmdd'
      },
      display: {
        theme: 'dark',
        icons: {
          time: 'bi bi-clock',
          date: 'bi bi-calendar',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        },
        components: {
          clock: false,
          hours: false,
          minutes: false,
          seconds: false,
          useTwentyfourHour: undefined
        }
      }
    });
  }
}
