import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-nested-form-item"
export default class extends Controller {
  connect() {
  }

  removeItem() {
    this.element.lastElementChild.lastElementChild.value = "true";
    this.element.style.display = "none";
  }
}
