import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="toggle-admin-only-view"
export default class extends Controller {
  static targets = [ "toggle", "submit" ];

  connect() {
    var query = window.location.search;
    if (query.includes("view_admins=1")) {
      this.toggleTarget.checked = true;
    }
  }

  toggle() {
    Rails.fire(this.submitTarget, 'submit');
  }
}
