import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="messages-pagination"
export default class extends Controller {
  static targets = [ "pagination" ];

  connect() {
    this.updatePaginationLinks();
  }

  updatePaginationLinks() {
    var pageLinks = this.paginationTarget.querySelectorAll("a");
    pageLinks.forEach((link) => {
      var href = link.href
      link.setAttribute("href", `/chats/${this.getID()}/load_more_messages?` + href.slice(href.lastIndexOf('?')+1));
      link.setAttribute("data-turbo-method", "post");
    });
  }

  getID() {
		return this.data.get('id');
	}
}
