import { Controller } from "@hotwired/stimulus"
import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';

// Connects to data-controller="linked-datepickers"
export default class extends Controller {
  static targets = [ "toggle", "start", "end" ];
  connect() {
    this.toggle();
  }

  toggle() {
    if (this.toggleTarget.checked == true) {
      this.set_date_pickers();
    } else {
      this.set_datetime_pickers();
      if (!this.startTarget.value.includes("AM") && !this.startTarget.value.includes("PM")) {
        this.startTarget.value = this.startTarget.value + " 06:00AM"
      }
      if (this.endTarget.value != "" && !this.endTarget.value.includes("AM") && !this.endTarget.value.includes("PM")) {
        this.endTarget.value = this.endTarget.value + " 07:00AM"
      }
    }
  }

  set_datetime_pickers() {
    const linkedPicker1Element = document.getElementById('linkedPickers1');
    const linked1 = new TempusDominus(linkedPicker1Element, {
      localization: {
        locale: 'default',
        format: 'MMM dd, yyyy HH:mmT'
      },
      display: {
        theme: 'dark',
        icons: {
          time: 'bi bi-clock',
          date: 'bi bi-calendar',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        }
      }
    });
    const linked2 = new TempusDominus(document.getElementById('linkedPickers2'), {
      useCurrent: false,
      localization: {
        locale: 'default',
        format: 'MMM dd, yyyy HH:mmT'
      },
      display: {
        theme: 'dark',
        icons: {
          time: 'bi bi-clock',
          date: 'bi bi-calendar',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        },
      }
    });

    //using event listeners
    linkedPicker1Element.addEventListener(Namespace.events.change, (e) => {
      linked2.updateOptions({
        restrictions: {
          minDate: e.detail.date,
        },
      });
    });

    //using subscribe method
    const subscription = linked2.subscribe(Namespace.events.change, (e) => {
      linked1.updateOptions({
        restrictions: {
          maxDate: e.date,
        },
      });
    });

    // event listener can be unsubscribed to:
    // subscription.unsubscribe();
  }

  set_date_pickers() {
    const linkedPicker1Element = document.getElementById('linkedPickers1');
    const linked1 = new TempusDominus(linkedPicker1Element, {
      localization: {
        locale: 'default',
        format: 'MMM dd, yyyy'
      },
      display: {
        theme: 'dark',
        icons: {
          time: 'bi bi-clock',
          date: 'bi bi-calendar',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        },
        components: {
          clock: false,
          hours: false,
          minutes: false,
          seconds: false,
          useTwentyfourHour: undefined
        }
      }
    });
    const linked2 = new TempusDominus(document.getElementById('linkedPickers2'), {
      useCurrent: false,
      localization: {
        locale: 'default',
        format: 'MMM dd, yyyy'
      },
      display: {
        theme: 'dark',
        icons: {
          time: 'bi bi-clock',
          date: 'bi bi-calendar',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        buttons: {
          today: true,
          clear: true,
          close: true,
        },
        components: {
          clock: false,
          hours: false,
          minutes: false,
          seconds: false,
          useTwentyfourHour: undefined
        }
      }
    });

    //using event listeners
    linkedPicker1Element.addEventListener(Namespace.events.change, (e) => {
      linked2.updateOptions({
        restrictions: {
          minDate: e.detail.date,
        },
      });
    });

    //using subscribe method
    const subscription = linked2.subscribe(Namespace.events.change, (e) => {
      linked1.updateOptions({
        restrictions: {
          maxDate: e.date,
        },
      });
    });

    // event listener can be unsubscribed to:
    // subscription.unsubscribe();
  }
}
