import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="add-nested-form-item"
export default class extends Controller {
  connect() {
  }

  addCollaborator(){
    var userInput = this.element.firstElementChild.value;
    userInput = userInput.replace("@", "");
    if (userInput) {
      var userId = "";
      var recordType = this.getrecordType();
      var collaborationId = parseInt(Date.now());
      var collaborators = document.querySelector("#item_collaborators");
      var collabBtns = collaborators.getElementsByTagName("button");
      var alreadyCollab = false;
      for (let i = 0; i < collabBtns.length; i++) {
        var username = String(collabBtns[i].innerHTML).replace("@", "").replace(`<i class="bi bi-x-lg"></i>`, "").trim();
        if (username === userInput) {
          alreadyCollab = true;
        }
      }
      if (alreadyCollab == true ) {
        var collaboratorsNotice = document.querySelector("#item_collaborators_notice");
        var notice = `<div class="alert alert-primary alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>User is already a collaborater.</div>`;
        collaboratorsNotice.innerHTML = notice;
      } else {
        Rails.ajax({
          url: `/users/get_id/${userInput}`,
          type: 'get',
          success: function(data) {
            userId = data['id'];
            if (userId) {
              var collaborators = document.querySelector("#item_collaborators");
              var newCollaborator = `<div data-controller="remove-nested-form-item"><div class="float-start mb-1 me-1"><button name="button" type="button" class="btn btn-sm btn-danger" data-action="remove-nested-form-item#removeItem">@${userInput}<i class="bi bi-x-lg"></i></button><input autocomplete="off" type="hidden" name="${recordType}[collaborations_attributes][${collaborationId}][id]" id="${recordType}_collaborations_attributes_${collaborationId}_id"><input autocomplete="off" type="hidden" value="${userId}" name="${recordType}[collaborations_attributes][${collaborationId}][user_id]" id="${recordType}_collaborations_attributes_${collaborationId}_user_id"><input autocomplete="off" type="hidden" value="false" name="${recordType}[collaborations_attributes][${collaborationId}][_destroy]" id="${recordType}_collaborations_attributes_${collaborationId}__destroy"></div></div>`
              collaborators.insertAdjacentHTML("beforeend", newCollaborator);
              var collaboratorsNotice = document.querySelector("#item_collaborators_notice");
              var notice = "";
              collaboratorsNotice.innerHTML = notice;
            } else {
              var collaboratorsNotice = document.querySelector("#item_collaborators_notice");
              var notice = `<div class="alert alert-warning alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>Username does not exist.</div>`;
              collaboratorsNotice.innerHTML = notice;
            }
          }
        });
      }
      this.element.firstElementChild.value = "";
    }
  }

  addMember(){
    var userInput = this.element.firstElementChild.value;
    userInput = userInput.replace("@", "");
    if (userInput) {
      var userId = "";
      var recordType = "group";
      var memberId = parseInt(Date.now());
      var members = document.querySelector("#group_members");
      var memberBtns = members.getElementsByTagName("button");
      var alreadyMember = false;
      for (let i = 0; i < memberBtns.length; i++) {
        var username = String(memberBtns[i].innerHTML).replace("@", "").replace(`<i class="bi bi-x-lg"></i>`, "").trim();
        if (username === userInput) {
          alreadyMember = true;
        }
      }
      if (alreadyMember == true ) {
        var membersNotice = document.querySelector("#group_members_notice");
        var notice = `<div class="alert alert-primary alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>User is already a member.</div>`;
        membersNotice.innerHTML = notice;
      } else {
        Rails.ajax({
          url: `/users/get_id/${userInput}`,
          type: 'get',
          success: function(data) {
            userId = data['id'];
            if (userId) {
              var members = document.querySelector("#group_members");
              var newMember = `<div data-controller="remove-nested-form-item"><div class="float-start mb-1 me-1"><button name="button" type="button" class="btn btn-sm btn-danger" data-action="remove-nested-form-item#removeItem">@${userInput}<i class="bi bi-x-lg"></i></button><input autocomplete="off" type="hidden" name="${recordType}[group_members_attributes][${memberId}][id]" id="${recordType}_group_members_attributes_${memberId}_id"><input autocomplete="off" type="hidden" value="${userId}" name="${recordType}[group_members_attributes][${memberId}][user_id]" id="${recordType}_group_members_attributes_${memberId}_user_id"><input autocomplete="off" type="hidden" value="false" name="${recordType}[group_members_attributes][${memberId}][_destroy]" id="${recordType}_group_members_attributes_${memberId}__destroy"></div></div>`
              members.insertAdjacentHTML("beforeend", newMember);
              var membersNotice = document.querySelector("#group_members_notice");
              var notice = "";
              membersNotice.innerHTML = notice;
            } else {
              var membersNotice = document.querySelector("#group_members_notice");
              var notice = `<div class="alert alert-warning alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>Username does not exist.</div>`;
              membersNotice.innerHTML = notice;
            }
          }
        });
      }
      this.element.firstElementChild.value = "";
    }
  }

  addGroup(){
    var userInput = this.element.firstElementChild.value;
    if (userInput) {
      userInput = userInput.trim()
      var groupId = "";
      var recordType = this.getrecordType();
      var groupAssignmentId = parseInt(Date.now());
      var groups = document.querySelector("#item_groups");
      var groupBtns = groups.getElementsByTagName("button");
      var alreadyCollab = false;
      for (let i = 0; i < groupBtns.length; i++) {
        var groupname = String(groupBtns[i].innerHTML).replace(`<i class="bi bi-x-lg"></i>`, "").trim();
        if (groupname === userInput) {
          alreadyCollab = true;
        }
      }
      if (alreadyCollab == true ) {
        var groupsNotice = document.querySelector("#item_groups_notice");
        var notice = `<div class="alert alert-primary alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>Group is already added.</div>`;
        groupsNotice.innerHTML = notice;
      } else {
        Rails.ajax({
          url: `/groups/get_id/${encodeURIComponent(userInput)}`,
          type: 'get',
          success: function(data) {
            groupId = data['id'];
            if (groupId) {
              var groups = document.querySelector("#item_groups");
              var newCollaborator = `<div data-controller="remove-nested-form-item"><div class="float-start mb-1 me-1"><button name="button" type="button" class="btn btn-sm btn-danger" data-action="remove-nested-form-item#removeItem">${userInput}<i class="bi bi-x-lg"></i></button><input autocomplete="off" type="hidden" name="${recordType}[group_assignments_attributes][${groupAssignmentId}][id]" id="${recordType}_group_assignments_attributes_${groupAssignmentId}_id"><input autocomplete="off" type="hidden" value="${groupId}" name="${recordType}[group_assignments_attributes][${groupAssignmentId}][group_id]" id="${recordType}_group_assignments_attributes_${groupAssignmentId}_group_id"><input autocomplete="off" type="hidden" value="false" name="${recordType}[group_assignments_attributes][${groupAssignmentId}][_destroy]" id="${recordType}_group_assignments_attributes_${groupAssignmentId}__destroy"></div></div>`
              groups.insertAdjacentHTML("beforeend", newCollaborator);
              var groupsNotice = document.querySelector("#item_groups_notice");
              var notice = "";
              groupsNotice.innerHTML = notice;
            } else {
              var groupsNotice = document.querySelector("#item_groups_notice");
              var notice = `<div class="alert alert-warning alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>Group does not exist.</div>`;
              groupsNotice.innerHTML = notice;
            }
          }
        });
      }
      this.element.firstElementChild.value = "";
    }
  }

  addTag(){
    var userInput = this.element.firstElementChild.value;
    if (userInput) {
      userInput = userInput.trim()
      var tagId = "";
      var recordType = this.getrecordType();
      var TaggingId = parseInt(Date.now());
      var tags = document.querySelector("#item_tags");
      var tagBtns = tags.getElementsByTagName("button");
      var alreadyTag = false;
      for (let i = 0; i < tagBtns.length; i++) {
        var tagname = String(tagBtns[i].innerHTML).replace(`<i class="bi bi-x-lg"></i>`, "").trim();
        if (tagname === userInput) {
          alreadyTag = true;
        }
      }
      if (alreadyTag == true ) {
        var tagsNotice = document.querySelector("#item_tags_notice");
        var notice = `<div class="alert alert-primary alert-dismissible" role="alert"><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>Tag is already added.</div>`;
        tagsNotice.innerHTML = notice;
      } else {
        Rails.ajax({
          url: `/tags/get_id/${encodeURIComponent(userInput)}`,
          type: 'get',
          success: function(data) {
            tagId = data['id'];
            if (tagId) {
              var tags = document.querySelector("#item_tags");
              var newTag = `<div data-controller="remove-nested-form-item"><div class="float-start mb-1 me-1"><button name="button" type="button" class="btn btn-sm btn-danger" data-action="remove-nested-form-item#removeItem">${userInput}<i class="bi bi-x-lg"></i></button><input autocomplete="off" type="hidden" name="${recordType}[taggings_attributes][${TaggingId}][id]" id="${recordType}_taggings_attributes_${TaggingId}_id"><input autocomplete="off" type="hidden" value="${tagId}" name="${recordType}[taggings_attributes][${TaggingId}][tag_id]" id="${recordType}_taggings_attributes_${TaggingId}_tag_id"><input autocomplete="off" type="hidden" value="false" name="${recordType}[taggings_attributes][${TaggingId}][_destroy]" id="${recordType}_taggings_attributes_${TaggingId}__destroy"></div></div>`
              tags.insertAdjacentHTML("beforeend", newTag);
              var tagsNotice = document.querySelector("#item_tags_notice");
              var notice = "";
              tagsNotice.innerHTML = notice;
            } else {
              var tags = document.querySelector("#item_tags");
              var newTag = `<div data-controller="remove-nested-form-item"><div class="float-start mb-1 me-1"><button name="button" type="button" class="btn btn-sm btn-danger" data-action="remove-nested-form-item#removeItem">${userInput}<i class="bi bi-x-lg"></i></button><input autocomplete="off" type="hidden" name="${recordType}[taggings_attributes][${TaggingId}][id]" id="${recordType}_taggings_attributes_${TaggingId}_id"><input autocomplete="off" type="hidden" value="${userInput}" name="${recordType}[taggings_attributes][${TaggingId}][tag_name]" id="${recordType}_taggings_attributes_${TaggingId}_tag_name"><input autocomplete="off" type="hidden" value="${tagId}" name="${recordType}[taggings_attributes][${TaggingId}][tag_id]" id="${recordType}_taggings_attributes_${TaggingId}_tag_id"><input autocomplete="off" type="hidden" value="false" name="${recordType}[taggings_attributes][${TaggingId}][_destroy]" id="${recordType}_taggings_attributes_${TaggingId}__destroy"></div></div>`
              tags.insertAdjacentHTML("beforeend", newTag);
              var tagsNotice = document.querySelector("#item_tags_notice");
              var notice = "";
              tagsNotice.innerHTML = notice;
            }
          }
        });
      }
      this.element.firstElementChild.value = "";
    }
  }

  getrecordType() {
    return this.data.get('record-type').toLowerCase();
  }
}
