import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggle", "hide" ];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.toggleTarget.checked) {
      this.hideTarget.style.display = "block";
    } else {
      this.hideTarget.style.display = "none";
    }
  }
}
