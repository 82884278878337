import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-submit"
export default class extends Controller {
  static targets = [ "submit", "input" ];

  initialize() {
    this.submit = this.submit.bind(this);
  }

  connect() {
    document.addEventListener("keydown", this.submit);
  }

  disconnect() {
    document.removeEventListener("keydown", this.submit);
  }

  submit(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      this.submitTarget.click();
      this.inputTarget.value = "";
      event.preventDefault()
    };
  }
}
