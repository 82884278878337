import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="real-time-chat-index"
export default class extends Controller {
  connect() {
    var pinnedChats = document.getElementsByClassName("pinned");
    var pinnedChatsCount = pinnedChats.length;
    var matchingIndexItems = document.getElementsByClassName(this.getID());
    var onFirstPage = false;

    if (!document.getElementsByClassName("page-item active")[0] || (document.getElementsByClassName("page-item active")[0].children[0].innerHTML == '1')) {
      onFirstPage = true;
    }
    if (!onFirstPage) {
      matchingIndexItems[0].remove();
    } else {
      if (matchingIndexItems[1]) {
        matchingIndexItems[1].remove();
      }
      if ((pinnedChatsCount > 0) && (matchingIndexItems[0].classList.contains("unpinned"))) {
        var newItem = matchingIndexItems[0].cloneNode(true);
        newItem.removeAttribute("data-controller");
        pinnedChats[pinnedChatsCount - 1].parentNode.insertBefore(newItem,pinnedChats[pinnedChatsCount - 1].nextSibling);
        if (matchingIndexItems[0]) {
          matchingIndexItems[0].remove();
        }
      }
    }
  }

  getID() {
		return this.data.get('id');
	}
}
